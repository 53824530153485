require('./bootstrap');

import Modal from "bootstrap/js/dist/modal";
import Collapse from "bootstrap/js/dist/collapse";

let selectPickers = document.querySelectorAll('.selectpicker');
if (selectPickers) {
    selectPickers.forEach((v, k) => {
        if (v.init)
            return;
        v.init = true;

        let select = document.createElement('div');
        select.classList.add("custom-select-picker");
        select.innerHTML = `<i class="far fa-chevron-down "></i>`;

        let options = v.querySelectorAll('option');
        let selected = document.createElement('div');
        selected.classList.add('custom-select-selected');
        selected.innerHTML = "Bitte auswählen";
        select.appendChild(selected);
        let optionWrapper = document.createElement('div');
        optionWrapper.classList.add('custom-select-options');

        selected.addEventListener('click', () => {
            select.classList.toggle('open');
            let height = 0;
            if (select.classList.contains('open')) {
                for (let i = 0; i < optionWrapper.children.length; i++) {
                    height += optionWrapper.children[i].getBoundingClientRect().height;
                }
                if (height > 400) {
                    height = 400;
                }
            }
            optionWrapper.style.maxHeight = `${height + 5}px`;
        });

        window.addEventListener('click', function (e) {
            if (select.contains(e.target)) {
            } else {
                select.classList.remove('open');
                optionWrapper.style.maxHeight = `0`;
            }
        });

        let pickerOptions = [];
        options.forEach((oV, oK) => {
            if (oV.selected) {
                selected.innerHTML = oV.innerHTML;
            }
            if (oV.disabled)
                return;
            let option = document.createElement('div');
            option.classList.add('custom-select-option');
            if (oV.selected) {
                option.classList.add('selected');
            }
            for (let element in oV.dataset) {
                option.dataset[element] = oV.dataset[element];
            }
            option.dataset["value"] = oV.value;
            option.innerHTML = oV.innerHTML + `<i class="far fa-check"></i>`;
            option.addEventListener('click', () => {
                let text = "";

                options.forEach((element) => {
                    if (element.disabled)
                        return;
                    if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                        element.selected = false;
                    } else {
                        if (element.selected && option.dataset["value"] !== element.value) {
                            if (text)
                                text += ", ";
                            text += element.innerHTML;
                        }
                    }
                });
                pickerOptions.forEach((element) => {
                    if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                        element.classList.remove('selected');
                    }
                });
                option.classList.toggle('selected');
                options.forEach((element, oK) => {
                    if (element.value === option.dataset["value"]) {
                        element.selected = option.classList.contains('selected');
                        if (element.selected) {
                            if (text)
                                text += ", ";
                            text += element.innerHTML;
                        }
                    }
                });
                if (!text) {
                    text = "Bitte auswählen";
                }
                v.dispatchEvent(new Event('change'));
                selected.innerHTML = text;

                if (v.getAttribute('multiple') === undefined || v.getAttribute('multiple') === null) {
                    select.classList.remove('open');
                    optionWrapper.style.maxHeight = `0`;
                }
            });
            pickerOptions.push(option);
            optionWrapper.appendChild(option);
        });
        select.appendChild(optionWrapper);
        v.parentElement.appendChild(select);
        select.append(v);
    });
}
